// src/App.tsx
import * as React from "react";
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  Button,
  Container,
  Heading,
  Image,
  SimpleGrid,
  Stack,
} from "@chakra-ui/react";
import { theme } from "./theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from "./pages/Home";
import { Apply } from "./pages/Apply";
import { LearnMore } from "./pages/LearnMore";
import { RequestOffer } from "./pages/RequestOffer";
import { OpportunitiesPage } from "./pages/OpportunitiesPage";
import { Team } from "./pages/Team";
import { Opportunity } from "./pages/opportunity/index";

export const App = () => (
  <ChakraProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/learnmore" element={<LearnMore />} />
        <Route path="/requestoffer" element={<RequestOffer />} />
        <Route path="/opportunities" element={<OpportunitiesPage />} />
        <Route path="/team" element={<Team />} />
        <Route path="/opportunity/:opportunityId" element={<Opportunity />} />

      </Routes>

     
    </Router>
  </ChakraProvider>
);
