export default {
  black: '#171717',
  white: '#ffffff',

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  gray: {
    25: '#FBFBFB',
    50: '#F5F5F5',
    100: '#E5E5E5',
    200: '#D3D3D3',
    300: '#B7B7B7',
    400: '#8C8C8C',
    500: '#585858',
    600: '#464646',
    700: '#343434',
    800: '#232323',
    900: '#121212',
    950: '#050505',
  },
  blue: {
    50: '#ECEEFE',
    100: '#D8DDFD',
    200: '#B1BAFB',
    300: '#8B98F9',
    400: '#6475F7',
    500: '#3D53F5',
    600: '#3142C4',
    700: '#253293',
    800: '#182162',
    900: '#0C1131',
    950: '#060818',
  },
  mint: {
    50: '#F9FDFE',
    100: '#F4FAFD',
    200: '#E9F6FA',
    300: '#DDF1F8',
    400: '#D2EDF5',
    500: '#C7E8F3',
    600: '#9FBAC2',
    700: '#778B92',
    800: '#505D61',
    900: '#282E31',
    950: '#171A1C',
  },
  purple: {
    50: '#EEECFB',
    100: '#DDD9F7',
    200: '#BBB2EF',
    300: '#9A8CE8',
    400: '#7865E0',
    500: '#563FD8',
    600: '#4532AD',
    700: '#342682',
    800: '#221956',
    900: '#110D2B',
    950: '#090616',
  },
  yellow: {
    50: '#FFFBF5',
    100: '#FFF8EB',
    200: '#FFF1D7',
    300: '#FFE4AF',
    400: '#FFCF74',
    500: '#FFBB38',
    600: '#B38327',
    700: '#664B16',
    800: '#33250B',
    900: '#191306',
    950: '#0C0903',
  },
  red: {
    50: '#FDF1F4',
    100: '#FCE8EC',
    200: '#F9D0D9',
    300: '#F2A2B3',
    400: '#E95C7B',
    500: '#DF1642',
    600: '#9C0F2E',
    700: '#59091A',
    800: '#2D040D',
    900: '#160207',
    950: '#090103',
  },
  green: {
    50: '#F4FBF7',
    100: '#E8F6EE',
    200: '#D1EEDD',
    300: '#A3DDBC',
    400: '#5DC389',
    500: '#18A957',
    600: '#11763D',
    700: '#0A4423',
    800: '#052211',
    900: '#021109',
    950: '#010905',
  },
}
