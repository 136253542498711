//src/components/Sponsors.tsx
import { Button, Center, Container, SimpleGrid, Stack, Text, VStack, Box, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, FormControl, FormLabel, Input, FormHelperText, FormErrorMessage } from '@chakra-ui/react';
import { useState } from 'react';

import * as logos from './Logos'



interface SponsorModalProps {
    isOpen: boolean;
    onClose: () => void;
}






function SponsorModal({ isOpen, onClose }: SponsorModalProps) {

    // Optionally, manage loading and error states
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [formData, setFormData] = useState({
        companyName: '',
        mobileNumber: '',
        website: '',
        email: ''
    });
    const handleChange = (e: any) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        try {
            const response = await fetch('https://64e2-103-216-220-41.ngrok-free.app/api/sponsors', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (!response.ok) {
                throw new Error('Failed to submit form. Please try again.');
            }

            const result = await response.json();
            console.log('Success:', result);
            onClose(); // Close the modal after successful submission
        } catch (error) {
            console.error('Error submitting form:', error);
            setError(e.message || 'Something went wrong. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Become a Sponsor</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isRequired>
                        <FormLabel>Company Name</FormLabel>
                        <Input name="companyName" onChange={handleChange} />
                    </FormControl>
                    <FormControl isRequired mt={4}>
                        <FormLabel>Mobile Number</FormLabel>
                        <Input name="mobileNumber" onChange={handleChange} />
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>Website</FormLabel>
                        <Input name="website" onChange={handleChange} />
                    </FormControl>
                    <FormControl isRequired mt={4}>
                        <FormLabel>Email</FormLabel>
                        <Input name="email" type="email" onChange={handleChange} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={handleSubmit}>Submit</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
}


export const Sponsors = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();



    return (
        <Container py={{ base: '12', md: '16' }}>

            <SponsorModal isOpen={isOpen} onClose={onClose} />

            <Stack spacing="8">
                <Text
                    fontSize={{ base: 'md', md: 'lg' }}
                    fontWeight="medium"
                    color="fg.muted"
                    textAlign="center"
                >
                    These companies support our mission to empower Australia's future with decentralised AI if you'd liek to support us please contact us at admin@australianaiweb3foundation.org.
                </Text>
                <SimpleGrid gap={{ base: '4', md: '6' }} columns={{ base: 2, md: 3 }}>
                    {Object.entries(logos).map(([name, Logo]) => (
                        <VStack >
                            <Box
                                key={name}
                                bg="bg.surface"
                                py={{ base: '4', md: '8' }}
                                boxShadow="sm"
                                borderRadius="lg"
                                minW="300px"
                            >
                                <Center>
                                    <Logo h={{ base: '8', md: '10' }} maxW="180px" fill="fg.emphasized" />
                                </Center>
                                <Center>
                                    <VStack>
                                        {/* <Center > */}
                                        <Text margin={5} textAlign="center">Take advantage of this sponsorship opportunity to place your company at the heart of AI empowerment.</Text>

                                        {/* </Center> */}


                                        <Button size='xs' onClick={onOpen}>Click Here to become a sponsor</Button>


                                    </VStack>


                                </Center>

                            </Box>


                        </VStack>


                    ))}
                </SimpleGrid>
            </Stack>
        </Container>



    )
};