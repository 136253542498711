import { Button, Drawer, DrawerBody, DrawerContent, DrawerProps, Stack, Link as ChakraLink, useDisclosure, } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';





export const MobileDrawer = ({ onClose, ...props }: Omit<DrawerProps, 'children'>) => {
  const mobileNavbar = useDisclosure();

  // Inside your component
  const navigate = useNavigate();
  const location = useLocation();





  const handleLinkClick = (e: any, path: string) => {
    e.preventDefault(); // Prevent default link behavior
    onClose(); // Close the drawer
    navigate(path); // Navigate to the specified path
  };


  const handleOtherLinkClick = (e: any, anchorId: any) => {
    e.preventDefault(); // Prevent default anchor link behavior
    onClose(); // Close the drawer

    // Find the element and scroll to it
    const element = document.getElementById(anchorId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Drawer placement="top" onClose={onClose} {...props}>
      <DrawerContent>
        <DrawerBody mt="16">
          <Stack spacing="6" align="stretch">
            {['Team', 'Opportunities', 'Learn More'].map((item) => {
              let path = "/";
              if (item === "Team") path = "/team";
              if (item === "Opportunities") path = "/opportunities";
              if (item === "Learn More") path = "#video";

              return (
                <ChakraLink
                  key={item}
                  onClick={(e) => handleLinkClick(e, path)}
                  style={{ width: '100%', display: 'block' }}
                >
                  <Button size="lg" variant="text" colorScheme="gray" width="100%">
                    {item}
                  </Button>
                </ChakraLink>
              );
            })}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};