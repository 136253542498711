// src/pages/Home.tsx
import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Image,
    AspectRatio,
    SimpleGrid,
    Stack,
    HStack,
    Link,
    Icon
} from "@chakra-ui/react";

import { StatsWithNumbers } from "../components/StatsWithNumbers";
import { StoriesWithAccordion } from "../components/StoriesWithAccordion";
import { Opportunities } from "../components/Opportunities";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";
import { founders, advisors } from "../data/teamdata";
import { SquareImage } from "../components/SquareImage";
import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa'

export const Team = () => {
    return (
        <>
            <NavBarLogic></NavBarLogic>

            <Box as="section">
                <Container py={{ base: "16", md: "24" }}>
                    <Stack spacing={{ base: "8", md: "10" }}>
                        <Stack spacing={{ base: "4", md: "5" }} align="center">
                            <Heading size={{ base: "sm", md: "md" }}>
                                Team
                            </Heading>
                            <Text
                                color="fg.muted"
                                maxW="2xl"
                                textAlign="center"
                                fontSize="xl"
                            >
                                Here is our growing team and list of advisors.
                            </Text>
                        </Stack>
                    </Stack>





                    <Box bg="bg.surface" mt={40}>
                        <Container py={{ base: '16', md: '24' }}>
                            <Stack spacing={{ base: '12', md: '16' }}>

                                <Stack spacing="3">
                                    <Text fontSize={{ base: 'sm', md: 'md' }} color="accent" fontWeight="semibold">
                                        
                                    </Text>
                                    <Stack spacing={{ base: '4', md: '5' }}>
                                        <Heading size={{ base: 'sm', md: 'md' }}>Founders</Heading>
                                        <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                                            This company was formed by a group of individuals who wanted to make a difference in the world.
                                        </Text>
                                    </Stack>
                                </Stack>



                                <SimpleGrid
                                    columns={{ base: 1, md: 2, lg: 3 }}
                                    columnGap="8"
                                    rowGap={{ base: '10', md: '16' }}
                                >
                                    {founders.map((member) => (
                                        <Box key={member.name}>
                                            <Stack spacing="4">
                                                <Stack spacing={{ base: '4', md: '5' }}>
                                                    <SquareImage src={member.image} alt={member.name} />
                                                    <Stack>
                                                        <Box>
                                                            <Text fontSize="lg" fontWeight="medium">
                                                                {member.name}
                                                            </Text>
                                                            <Text color="accent">{member.role}</Text>
                                                        </Box>
                                                        <Text color="fg.muted">{member.description}</Text>
                                                    </Stack>
                                                </Stack>
                                                <HStack spacing="4" color="fg.subtle">
                                                    {[FaGithub, FaLinkedin, FaTwitter].map((item, id) => (
                                                        <Link href="#" key={id}>
                                                            <Icon as={item} boxSize="5" />
                                                        </Link>
                                                    ))}
                                                </HStack>
                                            </Stack>
                                        </Box>
                                    ))}
                                </SimpleGrid>






                                <Stack spacing="3">
                                    <Text fontSize={{ base: 'sm', md: 'md' }} color="accent" fontWeight="semibold">
                                        
                                    </Text>
                                    <Stack spacing={{ base: '4', md: '5' }}>
                                        <Heading size={{ base: 'sm', md: 'md' }}>Advisors</Heading>
                                        <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                                          We have a team of advisors who help guide us in the right directions.
                                        </Text>
                                    </Stack>
                                </Stack>



                                <SimpleGrid
                                    columns={{ base: 1, md: 2, lg: 3 }}
                                    columnGap="8"
                                    rowGap={{ base: '10', md: '16' }}
                                >
                                    {advisors.map((member) => (
                                        <Box key={member.name}>
                                            <Stack spacing="4">
                                                <Stack spacing={{ base: '4', md: '5' }}>
                                                    <SquareImage src={member.image} alt={member.name} />
                                                    <Stack>
                                                        <Box>
                                                            <Text fontSize="lg" fontWeight="medium">
                                                                {member.name}
                                                            </Text>
                                                            <Text color="accent">{member.role}</Text>
                                                        </Box>
                                                        <Text color="fg.muted">{member.description}</Text>
                                                    </Stack>
                                                </Stack>
                                                <HStack spacing="4" color="fg.subtle">
                                                    {[FaGithub, FaLinkedin, FaTwitter].map((item, id) => (
                                                        <Link href="#" key={id}>
                                                            <Icon as={item} boxSize="5" />
                                                        </Link>
                                                    ))}
                                                </HStack>
                                            </Stack>
                                        </Box>
                                    ))}
                                </SimpleGrid>
                            </Stack>
                        </Container>
                    </Box>







                </Container>
            </Box>

            <Box id="footer">
                <Footer />
            </Box>
        </>
    );
};
