// src/pages/Home.tsx
import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Button,
  Image,
  AspectRatio,
  SimpleGrid,
  Stack,
  Link as ChakraLink,
  Center,
  Flex
} from "@chakra-ui/react";



import { StatsWithNumbers } from "../components/StatsWithNumbers";
import { StoriesWithAccordion } from "../components/StoriesWithAccordion";
import { Opportunities } from "../components/Opportunities";
import { NavBarLogic } from "../components/NavBarLogic";
import { Footer } from "../components/Footer";
import { useName } from "../contexts/NameContext";
import { CountdownTimer } from "../components/CountdownTimer";
import { AboutUs } from "../components/AboutUs";
import { Sponsors } from "../components/Sponsors";
import { News } from "../components/News";
import { Events } from "../components/Events";

export const HomePage = () => {
  const { name } = useName();

  return (
    <>
      <NavBarLogic ></NavBarLogic>




      <Box id="hero">
        <>
          <Image
            alt="Placeholder Image"
            src="../assets/web3-australia.webp"
            objectFit="cover"
            // objectPosition="center -140px"
            // maxH={{ base: 'sm', md: 'lg' }}
            width="full"
          />

          <Box id="timer">
            <CountdownTimer />
          </Box>
          <Container py={{ base: '16', md: '24' }}>
            <SimpleGrid columns={{ base: 1, md: 2 }} columnGap={16} rowGap={4}>
              <Heading size={{ base: 'md', md: 'xl' }}>Empowering Australia's Future with Decentralised AI</Heading>
              <Stack spacing={{ base: '6', md: '8' }} justifyContent="center">
                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                  Join the Australian AI Web3 Community in shaping a collaborative, transparent, and inclusive AI-powered future.
                </Text>
                <Stack direction={{ base: 'column', md: 'row' }} spacing="3">
                  <ChakraLink href="https://discord.gg/kMsfnaRx">
                    <Button variant="primary" >
                      Collaborate Now
                    </Button>
                  </ChakraLink>
                  <ChakraLink href="#video">
                    <Button variant="secondary" >
                      Learn more
                    </Button>
                  </ChakraLink>
                </Stack>
              </Stack>
            </SimpleGrid>
          </Container>
        </>


      </Box>




      <Box id="about">
        {/* <StoriesWithAccordion /> */}
        <AboutUs />


      </Box>

      <Box id="video" h="700px">
        {/* <StatsWithNumbers /> */}
        {/* <Center> */}

        <Flex justifyContent="center" alignItems="center" w="100%" h="100%"> {/* Centers content both horizontally and vertically */}
          <iframe
            width="90%"
            height="90%"
            src="https://www.youtube.com/embed/1rVihrDo3rM?si=RFUpVq5j_15OMVST"
            title="YouTube video player"
            // frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          // referrerpolicy="strict-origin-when-cross-origin"
          // allowfullscreen
          ></iframe>
        </Flex>
        {/* </Center> */}
      </Box>

      <Box id="timer">
        <CountdownTimer />
      </Box>

      <Box id="sponsors">
        {/* <Opportunities /> */}
        <Sponsors />
      </Box>

      <Box id="news">
        {/* <Opportunities /> */}
        {/* <News /> */}
      </Box>

      <Box id="events">
        
        <Events />
      </Box>

      <Box id="footer">
        <Footer />


      </Box>
    </>
  );
};
